import Vue from 'vue';
import Btn from '@/components/base/btn/';
import apiRepositories, { repositories } from '../../api/api-repositories';
import { MyLocalStorage } from '../../utils/storage';

export default Vue.extend({
  name: 'Login',
  components: {
    Btn,
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    handleSubmit() {
      console.log(this.username, this.password);
      repositories.token.index({ username: this.username, password: this.password }).then(res => {
        if (res.token) {
          MyLocalStorage.set('userInfo', res);
          MyLocalStorage.set('token', res.token);
          this.$router.push({ path: 'admin' });
        }
      });
    },
  },
});
